body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f0f0f0;
}

header {
  background-color: white;
  color: black;
}

footer {
  color: black;
  padding: 0.2em;
  text-align: center;
  font-size: smaller;
}

main {
  padding: 2em;
}

section {
  padding: 2em 0;
}

nav a {
  margin: 0 1em;
  color: black;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.resume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume {
  width: 75%;
  height: 125vh;
  border: none;
}

.close-button {
  background-color: #ff6666;
  color: white;
  border: none;
  padding: 0.5em 1.5em;
  font-size: 1em;
  cursor: pointer;
  border-radius: 25px;
  margin-bottom: 1em;
}

.close-button:hover {
  background-color: #ff4d4d;
}

/* Mobile styles */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    position: static;
    left: auto;
    transform: none;
    flex-direction: row;
    justify-content: center;
  }

  .about-me {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    padding: 1em;
  }

  .about-me-content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .about-me-text {
    padding-right: 0;
    text-align: center;
  }

  .about-me-text p,
  .about-me-text h2,
  .about-me-text h3 {
    text-align: center;
  }

  .about-me-text p {
    font-size: 1em;
  }

  .about-me-text h2 {
    font-size: 2em;
  }

  .about-me-text h3 {
    font-size: 1.2em;
  }

  .about-me-image img {
    max-width: 150px;
  }

  .resume-container {
    width: 100%;
    padding: 1em;
  }

  .resume {
    width: 100%;
    height: auto;
  }
}
