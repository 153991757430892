.project {
  display: flex; /* Use flexbox to align items side by side */
  align-items: center; /* Align items vertically centered */
  padding: 1em; /* Add padding for spacing */
  background-color: white; /* Card background color */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s, box-shadow 0.3s; /* Add transition for hover effect */
  margin: 20px 0; /* Adds space between projects */
}

.project:hover {
  background-color: #f9f9f9; /* Change background on hover */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}

.project-image {
  width: 35%; /* Adjust as needed */
  height: auto;
  margin-right: 20px; /* Space between image and text */
}

h2 {
  font-size: 2em;
  margin-left: 50px;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 90%;
  margin-left: 30px;
  justify-content: center; /* Center projects on wider screens */
}

/* Mobile styles */
@media (max-width: 600px) {
  h2 {
    font-size: 1.5em;
    margin-left: 0;
    text-align: center;
  }

  .project-list {
    width: 80%;
    align-items: center;
  }

  .project {
    margin-left: 0; /* Remove margin for mobile */
    flex-direction: column; /* Stack image below text on mobile */
    align-items: center; /* Center items */
  }

  .project-image {
    width: 80%; /* Adjust image width for mobile */
    margin-right: 0; /* Remove right margin for mobile */
    margin-top: 10px; /* Add space above the image */
  }
}
