body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f0f0f0;
}

header {
  background-color: white;
  color: black;
  padding: 1em;
}

footer {
  color: black;
  padding: 0.2em;
  text-align: center;
  font-size: smaller;
}

main {
  padding: 2em;
}

section {
  padding: 2em 0;
}

nav a {
  margin: 0 1em;
  color: black;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

.resume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resume {
  width: 75%;
  height: 125vh;
  border: none;
}

.close-button {
  background-color: #ff6666;
  color: white;
  border: none;
  padding: 0.5em 1.5em;
  font-size: 1em;
  cursor: pointer;
  border-radius: 25px;
  margin-bottom: 1em;
}

.close-button:hover {
  background-color: #ff4d4d;
}

.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-icons {
  position: fixed;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 1em;
  z-index: 10;
}

.about-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  flex: 1;
  border-radius: 5px;
  margin-left: 30px; /* Adjust this value as needed for spacing */
}

.about-me-content {
  display: flex;
  flex-direction: row; /* Row layout for larger screens */
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.about-me-text {
  flex: 1;
  padding-right: 1em;
  text-align: center;
}

.about-me-text p {
  font-size: 1.2em;
  margin: 1em 0;
}

.about-me-text h2 {
  font-size: 2.5em;
  margin: 0.5em 0;
}

.about-me-text h3 {
  font-size: 1.5em;
  margin: 0.5em 0;
  color: #777;
}

.contact-button {
  background-color: #ff6666;
  color: white;
  border: none;
  padding: 0.5em 1.5em;
  font-size: 1em;
  cursor: pointer;
  border-radius: 25px;
}

.contact-button:hover {
  background-color: #ff4d4d;
}

.about-me-image {
  margin-top: 1em; /* Add margin for spacing */
  margin-bottom: 1em; /* Add margin for spacing */
  display: flex;
  justify-content: center;
  margin-right: 1em; /* Add margin to separate from text */
}

.about-me-image img {
  border-radius: 50%;
  max-width: 200px;
  width: 80%;
  height: auto;
}

/* Mobile styles */
@media (max-width: 600px) {
  header,
  footer {
    padding: 1em;
  }

  main {
    padding: 1.5em;
  }

  .about-container {
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    position: absolute;
    left: auto;
    right: 10px;
    top: 12%;
    transform: translateX(-50%);
    flex-direction: row;
  }

  .about-me {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    padding: 1em;
  }

  .about-me-content {
    flex-direction: column; /* Column layout for mobile */
    align-items: center;
    width: 100%;
  }

  .about-me-text {
    padding-right: 0;
    text-align: center;
  }

  .about-me-text p {
    font-size: 1em;
  }

  .about-me-text h2 {
    font-size: 2em;
  }

  .about-me-text h3 {
    font-size: 1.2em;
  }

  .about-me-image {
    margin-right: 0; /* Remove margin in mobile view */
  }

  .about-me-image img {
    max-width: 150px;
  }

  .close-button {
    width: 100%; /* Full-width buttons for easier tapping */
    padding: 1em;
    font-size: 1.2em;
  }
}
