.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 2.5em;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left img {
  width: 2em;
  height: auto;
}

.email {
  display: flex;
  align-items: center;
  margin-left: 3em;
}

.email img {
  width: 1.3em;
}

span {
  font-size: 10px;
}

.header-right {
  display: flex;
}

.header-right a {
  margin-left: 1.5em;
  color: black;
  text-decoration: none;
  font-size: small;
}

.header-right a:hover {
  text-decoration: underline;
}

/* Mobile styles */
@media (max-width: 600px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 1em;
  }

  .header-left,
  .header-right {
    width: 100%;
    justify-content: space-between;
  }

  .header-left {
    margin-bottom: 1em;
  }

  .email {
    margin-left: 1em;
  }

  .header-right a {
    margin-left: 1em;
    font-size: 12px;
  }

  span {
    font-size: 12px;
  }
}
